import io from 'socket.io-client';
//constants
import { url } from '../config/constants';

let socketAdapter: ReturnType<typeof io>;

const socket = (ns: string) => {
  return io.connect(`${url}/${ns}`);
};

export default socket;
